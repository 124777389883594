import React, { useEffect } from "react";
import { Link } from "react-router";
import styled from "styled-components";
import { Button, BADI_COLORS, BUTTON_TYPES } from "app/badi-components";
import { Heading } from "@badi/badi-components";
import Tag from "../components/Tag";
import Image from "components/Image/Image";
import ProgressiveImg from "app/components/ProgressiveImg";
import { getPublicAsset } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import { Text, LinkButton } from "app/badi-components";
import EmptyState from "./empty-state";

import { getGoldUpgradePath } from "Sections/plans/routes";

import { getPathToRequests } from "../routes";
import { openYourCandidatesModal } from "../modals";
import LocalStorage, { STORAGE_KEYS } from "app/lib/local-storage/LocalStorage";

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 60px;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TagCategoryHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
`;

const UserDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
`;

UserDetailsSection.Membership = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

UserDetailsSection.Column = styled.div`
  border-left: 2px solid ${BADI_COLORS.ADDITIONAL.ADDITIONAL_11};
  padding-left: 10px;
`;

const RoomInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 70px;
`;

RoomInformation.Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & + & {
    border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  }
  padding: 30px 0;
`;

const NavigationIcon = styled.img`
  height: 50px;
  width: 50px;
  ${({ disabled }) => (disabled ? "opacity: 0.5" : "")};
  ${({ disabled }) => (disabled ? "" : "cursor: pointer")};
`;

const GoToRequestButtonContainer = styled.div`
  margin: 0 30px;
  margin-top: 325px;
`;

const GoToRequestButton = ({ to, ...props }) => {
  if (!to) {
    return (
      <GoToRequestButtonContainer>
        <NavigationIcon disabled={true} {...props} />
      </GoToRequestButtonContainer>
    );
  }
  return (
    <GoToRequestButtonContainer>
      <Link to={to}>
        <NavigationIcon {...props} />
      </Link>
    </GoToRequestButtonContainer>
  );
};

const RoomPicture = styled.img`
  width: 100px;
`;

const PersonalityAndInterestsSectionContainer = styled.div`
  position: relative;
  top: -3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 20px;
  padding: 0 3rem;
`;

const PersonalityAndInterestsSection = ({ tags }) => (
  <PersonalityAndInterestsSectionContainer>
    <Heading level={4}>
      {translate("inbox-lister.personality-and-interests.heading")}
    </Heading>
    {Object.values(tags).map(({ key, title, iconSrc, iconAlt, entries }) => (
      <div key={key}>
        <TagCategoryHeading>
          <Image src={iconSrc} alt={iconAlt} />
          <b>{title}</b>
        </TagCategoryHeading>
        {entries.map(({ id, label }) => (
          <Tag key={id}>{label}</Tag>
        ))}
      </div>
    ))}
  </PersonalityAndInterestsSectionContainer>
);

const CtaSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  padding: 15px 20px 5px 20px;
  box-shadow: 0px 10px 10px 5px rgba(50, 50, 50, 0.75);
  clip-path: inset(-10px 0px 1px 0px);
  align-items: center;
`;

CtaSection.TextSubsection = styled.div`
  flex-grow: 4;
  text-align: center;
`;

CtaSection.LinkButton = styled(LinkButton)`
  white-space: nowrap;
`;

CtaSection.AcceptAndRejectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const TopSectionContainer = styled.div`
  border-radius: 30px 30px 0 0;
  box-shadow: 0px -15px 30px 10px rgba(0, 0, 0, 0.16);
  padding: 50px;
  padding-bottom: 5rem;
  overflow: visible;
`;

const Container = styled.div`
  display: flex;
  flex-direction: horizontal;
  max-width: 920px;
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequestsSwiper = ({
  currentRequest,
  pathToPreviousRequest,
  pathToNextRequest,
  onClickAcceptConnection,
  onClickRejectConnection,
  isLoaded,
}) => {
  if (!currentRequest) {
    if (isLoaded) return <EmptyState />;
    return null;
  }

  useEffect(() => {
    const hasUserSeenFirstTimeListerRequestsInformationAlready =
      LocalStorage.getItem(
        STORAGE_KEYS.HAS_USER_SEEN_FIRST_TIME_LISTER_REQUESTS_INFORMATION,
      );
    if (!hasUserSeenFirstTimeListerRequestsInformationAlready) {
      openYourCandidatesModal();
      LocalStorage.setItem(
        STORAGE_KEYS.HAS_USER_SEEN_FIRST_TIME_LISTER_REQUESTS_INFORMATION,
        true,
      );
    }
  }, []);

  const { user, room, hasBadiGold, payToMatchRequired } = currentRequest;
  return (
    <Container>
      <GoToRequestButton
        to={pathToPreviousRequest}
        alt="go to previous request"
        src={getPublicAsset("icons/circle-chevron-left.svg")}
        position="left"
      />
      <FlexVertical>
        <TopSectionContainer>
          <FlexHorizontal>
            <Column>
              <ProgressiveImg
                src={user.picture}
                tinySrc={user.picture_small}
                alt="user image"
                style={{ width: "80%", alignSelf: "center" }}
              />
              <UserDetailsSection>
                <Heading level={3}>{user.name_and_age}</Heading>
                <UserDetailsSection.Membership>
                  <UserDetailsSection.Column>
                    <b>{translate("inbox-lister.member-since")}</b>
                    <div>{user.member_since}</div>
                  </UserDetailsSection.Column>
                  <UserDetailsSection.Column>
                    <b>{translate("inbox-lister.last-active")}</b>
                    <div>{user.last_active}</div>
                  </UserDetailsSection.Column>
                </UserDetailsSection.Membership>
              </UserDetailsSection>
            </Column>
            <RoomInformation>
              <Heading level={2}>
                {translate("inbox-lister.room-information.heading")}
              </Heading>
              <RoomInformation.Section>
                <div>
                  <b>{room.title}</b>
                  <div>{room.address}</div>
                </div>
                <div>
                  <RoomPicture src={room.picture} alt="picture of room" />
                </div>
              </RoomInformation.Section>
              <RoomInformation.Section>
                <div>
                  <div>{translate("inbox-lister.available-from")}</div>
                  <b>{room.availableFrom}</b>
                </div>
                <div style={{ alignSelf: "center" }}>
                  <img
                    src={getPublicAsset("icons/calendar-default.svg")}
                    alt="calendar icon"
                  />
                </div>
                <div>
                  <div>{translate("inbox-lister.minimum-stay")}</div>
                  <b>
                    {translate("inbox-lister.minimum-stay-count", {
                      days: room.minimumStay,
                    })}
                  </b>
                </div>
              </RoomInformation.Section>
              <RoomInformation.Section>
                <div>
                  <div>{translate("inbox-lister.monthly-price")}</div>
                  {room.billsIncluded && (
                    <Text body="12-Regular">{translate("bills.included")}</Text>
                  )}
                </div>
                <b>{room.price}</b>
              </RoomInformation.Section>
            </RoomInformation>
          </FlexHorizontal>
          <CtaSection>
            <CtaSection.TextSubsection>
              <Heading
                level={4}
                color={
                  hasBadiGold ? BADI_COLORS.ADDITIONAL.ADDITIONAL_06 : null
                }
              >
                {currentRequest.matchesStatusLabel}
              </Heading>
            </CtaSection.TextSubsection>
            <div>
              {payToMatchRequired ? (
                <CtaSection.LinkButton
                  href={getGoldUpgradePath({
                    roomId: room.id,
                    initiator: getPathToRequests(currentRequest.id),
                  })}
                  variant={BUTTON_TYPES.GOLD_GRADIENT}
                >
                  {translate("listing.overview.badi_gold.desktop_cta")}
                </CtaSection.LinkButton>
              ) : (
                <CtaSection.AcceptAndRejectContainer>
                  <Button
                    variant={BUTTON_TYPES.SECONDARY}
                    fullWidth={true}
                    onClick={onClickRejectConnection}
                  >
                    {translate("Reject")}
                  </Button>
                  <Button
                    fullWidth={true}
                    variant={hasBadiGold ? BUTTON_TYPES.GOLD_GRADIENT : null}
                    onClick={onClickAcceptConnection}
                  >
                    {translate("Accept")}
                  </Button>
                </CtaSection.AcceptAndRejectContainer>
              )}
            </div>
          </CtaSection>
        </TopSectionContainer>
        {Object.keys(user.tags).length > 0 && (
          <PersonalityAndInterestsSection tags={user.tags} />
        )}
      </FlexVertical>
      <GoToRequestButton
        to={pathToNextRequest}
        alt="go to next request"
        src={getPublicAsset("icons/circle-chevron-right.svg")}
        position="right"
      />
    </Container>
  );
};

export default RequestsSwiper;
