import React, { useEffect, useState } from "react";
import { Spacer } from "@badi/badi-components";
import { Container, Row } from "reactstrap";
import LayoutWrapper from "components/Layout/Wrapper";
import Footer from "components/Layout/Footer";
import PremiumBanner from "Sections/plans/banner";
import PendingReviewTopBanner from "Sections/reviews/pending-top-banner";
import Section from "./section-elements/section";
import TransactionsList from "./section-elements/transactions-list";
import { OverviewSectionWrapper, OverviewMainFrame } from "./styled-components";
import { TRANSACTIONS_COMPONENT } from "./constants";
import DanaBanner from "components/dana-banner";

const Overview = ({
  fetchedSummary,
  getListerScore,
  getOverviewSummary,
  getPendingReviews,
  getTransactions,
  isPremiumEnabled = false,
  listerScore = "",
  mainHeading = "",
  pendingReviews,
  resetOverview,
  sections = null,
  transactions = null,
  userPicture = "",
  visibleTransactions = false,
}) => {
  const [mainSection, setMainSection] = useState(null);
  const [restOfSections, setRestOfSections] = useState(null);

  useEffect(() => {
    if (!sections || sections.length == 0) {
      return;
    }

    const [main, ...rest] = sections;

    setMainSection(main);
    setRestOfSections(rest);

    if (main.components.includes(TRANSACTIONS_COMPONENT)) {
      getTransactions();
    }
  }, [sections]);

  useEffect(() => {
    getOverviewSummary();
    getListerScore();
    getPendingReviews();

    return resetOverview;
  }, []);

  return (
    <LayoutWrapper footer={<Footer campaign="overview_footer" />}>
      <DanaBanner />
      {pendingReviews.length > 0 && (
        <PendingReviewTopBanner
          reviewId={pendingReviews[0].id}
          screen="overview"
        />
      )}
      {fetchedSummary && !!mainSection ? (
        <OverviewMainFrame>
          <Spacer md={{ top: 8 }} top={3}>
            <Container>
              <Row>
                <OverviewSectionWrapper
                  lg={{ offset: 0, size: 7 }}
                  md={{ size: 12, offset: 0, order: 1 }}
                >
                  {visibleTransactions ? (
                    <TransactionsList />
                  ) : (
                    <Section
                      components={mainSection.components}
                      heading={mainSection.heading}
                      listerScore={listerScore}
                      mainSectionHeading={mainHeading}
                      subheading={mainSection.subheading}
                      transactions={transactions}
                      userPicture={userPicture}
                    />
                  )}
                </OverviewSectionWrapper>
                <OverviewSectionWrapper
                  lg={{ offset: 1, size: 4 }}
                  md={{ size: 12, offset: 0, order: 2 }}
                >
                  {restOfSections.map((section) => (
                    <Section
                      components={section.components}
                      heading={section.heading}
                      key={section.id}
                      subheading={section.subheading}
                    />
                  ))}
                </OverviewSectionWrapper>
              </Row>
            </Container>
          </Spacer>
        </OverviewMainFrame>
      ) : null}
    </LayoutWrapper>
  );
};

export default Overview;
