import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Helmet from "react-helmet";

import { translate } from "base/shared/Localization";

import LayoutWrapper from "components/Layout/Wrapper";
import HeaderLang from "components/header-lang";
import Footer from "components/Layout/Footer";

import TopBarHome from "./components/top-bar-home";
import FeaturedCities from "./components/featured-cities";
import FeaturedSearches from "./components/featured-searches";
import FeaturedBadiPlus from "./components/featured-badi-plus";
import SeoLinks from "./components/seo-links";
import Introduction from "./components/introduction";
import FindYourMatch from "./components/find-your-match";
import DownloadApp from "./components/download-app";
import Community from "./components/community";
import AnimatedSection from "./components/animated-section";
import DownloadAppBanner from "app/components/download-app-banner";
import { openSelectorModal } from "./components/selector-modal";

import { SEARCH_TYPES } from "../../constants";
import {
  INTRODUCTION,
  FEATURED_CITIES,
  FEATURED_SEARCHES,
  LINKED_CARDS,
  SEO_LINKS,
  META_DATA,
} from "./constants";
import DanaBanner from "app/components/dana-banner";

const Home = ({ countryCode, location, openLogin, isMobile }) => {
  const [searchType, setSearchType] = useState(SEARCH_TYPES.ROOM);
  const [showDistribution, setShowDistribution] = useState(true);

  LINKED_CARDS.resources[0].clickHandler = openLogin;

  const { title } = INTRODUCTION.find(({ selector }) => selector == searchType);

  useEffect(() => {
    if (showDistribution) {
      openSelectorModal(() => {
        setShowDistribution(false);
      });
    }
  }, [showDistribution]);

  if (showDistribution) {
    return null;
  }

  return (
    <>
      <LayoutWrapper
        footer={<Footer campaign="home_footer" />}
        infoBar={false}
        topBar={
          <TopBarHome
            title={translate(title)}
            searchType={searchType}
            setSearchType={setSearchType}
          />
        }
      >
        <AnimatedSection type="scaleDown">
          <Introduction
            resources={INTRODUCTION}
            spacing={40}
            visibleLayer={searchType}
          />
        </AnimatedSection>
        <AnimatedSection>
          <DanaBanner />
        </AnimatedSection>
        <AnimatedSection>
          <FeaturedBadiPlus key="featured-badi-plus" />
        </AnimatedSection>
        <AnimatedSection>
          <FeaturedCities
            {...FEATURED_CITIES}
            countryCode={countryCode}
            key="featured-cities"
          />
        </AnimatedSection>
        <AnimatedSection>
          <FeaturedSearches
            {...FEATURED_SEARCHES}
            dataQa="slider-home"
            key="featured-searches"
          />
        </AnimatedSection>
        <AnimatedSection>
          <FindYourMatch />
        </AnimatedSection>

        <AnimatedSection>
          <DownloadApp />
        </AnimatedSection>

        <AnimatedSection>
          <Community />
        </AnimatedSection>

        <AnimatedSection>
          <SeoLinks {...SEO_LINKS} key="seo-links" />
        </AnimatedSection>
        {isMobile && <DownloadAppBanner />}
        <Helmet
          meta={translate(META_DATA.meta, "content")}
          title={translate(META_DATA.title)}
        />
        <HeaderLang
          canonicalUrl={location?.pathname}
          relativePath={location?.pathname}
        />
      </LayoutWrapper>
    </>
  );
};

Home.defaultProps = {
  countryCode: null,
  location: {},
};

export default withRouter(Home);
