import React from "react";
import Image from "components/Image";
import { getPublicAsset } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import {
  BADI_COLORS,
  getGoldenGradient,
  unitizedPx,
  media,
  Text,
} from "app/badi-components";
import styled from "styled-components";

const Components = {
  Container: styled.div`
    background: ${getGoldenGradient()};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    padding-right: 15px;
    ${media.md`
      padding-right: 0;
    `}
  `,
  ImageWrapper: styled.div`
    width: ${unitizedPx(2)};
    margin-left: 0.5rem;
    margin-right: 1rem;

    ${media.md`
      width: ${unitizedPx(7)};
      margin: 0;
    `}
  `,
  TextSection: styled.div`
    max-width: 55rem;
  `,
};

const DanaBanner = () => {
  return (
    <Components.Container>
      <Components.ImageWrapper>
        <Image
          lazy={false}
          src={getPublicAsset("images/dynamic-modal/info.svg")}
        />
      </Components.ImageWrapper>
      <Components.TextSection>
        <Text bold={true} color={BADI_COLORS.NEUTRAL.NEUTRAL_80}>
          {translate("dana-banner.title")}
        </Text>
        <Text body={3} color={BADI_COLORS.NEUTRAL.NEUTRAL_80}>
          {translate("dana-banner.subtitle")}
        </Text>
      </Components.TextSection>
    </Components.Container>
  );
};

export default DanaBanner;
