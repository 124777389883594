import { chain, get, mapValues, shake, isEmpty } from "radash";
import { translate } from "base/shared/Localization";
import { getCDNUrl, getCurrencySymbol } from "base/shared/Utils";

const getTags = chain(
  (obj) => get(obj, "enquiry.user.tags", {}),
  (tags) =>
    mapValues(tags || {}, (entries, tagCategoryKey) => ({
      key: tagCategoryKey,
      title: translate(
        `profile.form.fields.tags.category.${tagCategoryKey}.name`,
      ),
      iconSrc: `/assets/icons/icon-${tagCategoryKey}.svg`,
      iconAlt: tagCategoryKey,
      entries,
    })),
  (tags) => shake(tags, ({ entries }) => isEmpty(entries)),
);

const userProfilePicturePlaceholder = `${getCDNUrl()}/assets/icons/profile_picture.svg`;

const InboxListerModel = {
  id: "connection.id",
  matchesStatusLabel: "enquiry.room.matches_status_label",
  hasBadiGold: "enquiry.room.premium_plan",
  payToMatchRequired: "enquiry.pay_to_match_required",
  user: {
    name_and_age: chain(
      (obj) => [
        get(obj, "enquiry.user.first_name") || "-",
        get(obj, "enquiry.user.age"),
      ],
      (nameAndAge) => nameAndAge.filter(Boolean).join(", "),
    ),
    picture: (obj) =>
      get(obj, "enquiry.user.cover_picture.width_500_url") ||
      userProfilePicturePlaceholder,
    picture_small: (obj) =>
      get(obj, "enquiry.user.cover_picture.width_27_url") ||
      userProfilePicturePlaceholder,
    member_since: "enquiry.user.metrics.member_since",
    last_active: "enquiry.user.metrics.last_connection",
    tags: getTags,
  },
  room: {
    id: "enquiry.room.id",
    title: "enquiry.room.title",
    picture: "enquiry.room.cover_picture.width_100_url",
    price: chain(
      (obj) => [
        get(obj, "enquiry.room.monthly_price.price"),
        get(obj, "enquiry.room.monthly_price.currency"),
      ],
      ([price, currency]) => `${getCurrencySymbol(currency)}${price}`,
    ),
    billsIncluded: "enquiry.room.monthly_price.bills_included",
    address: "enquiry.room.address",
    availableFrom: "enquiry.room.available_from",
    minimumStay: "enquiry.room.min_days",
  },
  nextStep: "connection.next_step",
};

export { InboxListerModel };
